import React from "react";
import { Tabs } from "antd"


const Core = () => {

  return  <>
    <Tabs
        tabPosition='left'
        items={[
          {"label" : "Perception of relationships", "key": "tending_perspective", "children": tabChildren["tending_to"]},
          {"label" : "Language of an ecology.", "key": "ecology", "children": tabChildren["language_ecology"]},
          {"label" : "Tech assisted evolution.", "key": "tech_assisted", "children": tabChildren["tech_assisted"]},
          {"label" : "Rewilding from the gaps.", "key": "rewilding_from_gaps", "children": tabChildren["rewilding_from_gaps"]},
          {"label" : "doors to warm gatherings", "key": "door_to_warmth", "children": tabChildren["door_to_warmth"]},
        ]}>
    </Tabs>
  </>
};


const tabChildren = {
  "tending_to": <img 
  width={280}
  src={"/images/tending_to.png"} 
  alt="vitality"
  />,
  "language_ecology": <img
  width={280}
  src={"/images/language_ecology.png"}
  alt="language"
  />,
  "tech_assisted": <img 
  width={280}
  src={"/images/tech_assisted.png"} 
  alt="logical types"
  />,
  "rewilding_from_gaps": <img
  width={280}
  src={"/images/rewilding_from_gaps.png"}
  alt="discovery matrix"
  />,
  "door_to_warmth": <img
  width={280}
  src={"/images/door_to_warmth.png"}
  alt="door to warmth"
  />,

}

export default Core;