import React, { useState } from 'react'
import { useAppState } from '../../state';
import { Button, Radio, Input, Form, Card, Switch, Modal } from "antd";
import type { RadioChangeEvent } from 'antd/lib/radio';
import { EntityType } from '../../types';
import addToSource from '../../hooks/buildRecipies/createAndAddEntity';
import useGetEntityDetails from '../../hooks/getDetails/useGetEntityDetails';
import { getAllowedEntityTypes } from '../../hooks/getDetails/linkable';
import ListAddedEntities from '../CSVBuilder/ListAddedEntities';
import { doc } from 'firebase/firestore';
import removeStringFromArrayField from '../../hooks/firestoreFns/removeStringFromArrayField';
import addStringToArrayField from '../../hooks/firestoreFns/addStringToArrayField';

const AddNewEntity = (props : {
    sourceID : string| undefined,
    targetEntityType?: EntityType,
    buttonText?: string,
}) => {

    const { db, userEntity } = useAppState();

    const { sourceID, targetEntityType, buttonText } = props;
    const sourceDetails = useGetEntityDetails(sourceID);
    const allowedEntityTypes = getAllowedEntityTypes( sourceDetails?.entityType)
    const [entityType, setEntityType] = useState<EntityType>( targetEntityType ? targetEntityType : 'context');
    const [name, setName] = useState<string|undefined>();
    const [description, setDescription] = useState<string|undefined>();
    const [isAdmin, setIsAdmin] = useState<boolean>(true);
    const [email, setEmail] = useState<string|undefined>();
    const [webLink, setWebLink] = useState<string|undefined>();
    const [addedEntities, setAddedEntities] = React.useState<{
        entityID: string,
        entityExists: boolean,
        connectionExists: boolean
      }[]>([]);
    
      const afterAdding =  (newID: string, entityExists: boolean, connectionExists: boolean ) => {
    


        setAddedEntities([{ 
          entityID: newID, 
          entityExists: entityExists,
          connectionExists: connectionExists,
        }, 
          ...addedEntities
        ])
    
      }
    
    const resetState = () => {
        setName(undefined);
        setDescription(undefined);
        setEmail(undefined);
        if (allowedEntityTypes && allowedEntityTypes.length > 0)
        { 
            
            setEntityType(targetEntityType ? targetEntityType : 'context');
        }
    }

    const onEntityTypeChange = ({ target: { value } }: RadioChangeEvent) => {
      console.log('radio checked', value);
      setEntityType(value);
    };


    const [isAddModalOpen, setIsAddModalOpen] = useState(false);

    
    if (sourceID === undefined) {
        return <></>
    }

    const onFinish = () => {
        console.log('Create Entity', name, description, entityType);
    
        if ( userEntity?.id && sourceID && name && description && entityType ) {
            console.log('Create Entity 2', name, description, entityType)

        addToSource(
                sourceID, 
                {
                    name, 
                    description,
                    entityType,
                    email,
                    webLink,
                    id: 'unassigned',
                    photoURL: 'unassigned',
                    creatorID: userEntity?.id
                },
                userEntity?.id,
                (entityType === 'person') ? isAdmin: true,
                db,
                afterAdding
        );}

        if (userEntity?.id) {
            let docRef = doc(db, 'userState', userEntity?.id);
            removeStringFromArrayField(docRef, 'onboardedSteps', entityType);
            addStringToArrayField(docRef, 'onboardedSteps', entityType);
        }
    }

    return   <React.Fragment>
        <Modal 
            open={isAddModalOpen}
            onCancel={() => setIsAddModalOpen(false)}
            footer={[<Button onClick={() => setIsAddModalOpen(false)}> close </Button>]}
        >

        <Card 
            bordered={false}
            style={{
            margin: 32
        }}>
            <Form onFinish={ () => {
                onFinish();
                resetState();
            }}>
                <Form.Item label='name' rules={[{ required: true, message: 'Please add a name.' }]}>
                    <Input 
                    value={name} 
                    onChange={
                        (e: any) => setName(e.target.value)
                    }/>
            
                </Form.Item>
                <Form.Item 
                    label='description' 
                    rules={[{ required: true, message: 'Please add a description.' }]}
                >
                    <Input value={description} onChange={(e: any) => setDescription(e.target.value)}/>
                </Form.Item>
                { !targetEntityType  && <Form.Item >
                    <Radio.Group
                        options={allowedEntityTypes}
                        onChange={onEntityTypeChange}
                        value={entityType}
                        optionType="button"
                    />
                </Form.Item>}

                {
                    entityType === 'person' && <Form.Item label='email'>
                        <Input value={email} onChange={(e: any) => setEmail(e.target.value)}/>
                    </Form.Item>
                }

                {
                    entityType === 'thing' && <Form.Item label='webLink'>
                        <Input value={webLink} onChange={(e: any) => setWebLink(e.target.value)}/>
                    </Form.Item>
                }
                {entityType === 'person' && !targetEntityType  && <Form.Item label='add as admin'>
                    <Switch onChange={setIsAdmin} />
                </Form.Item>
                }

                <Form.Item>
                    <Button htmlType="submit" disabled={
                        name === undefined 
                        || name === ""
                        || description === undefined 
                        || description === ""
                        || entityType === undefined 
                        }>
                        { buttonText ? buttonText: "Create Entity"}
                    </Button>
                </Form.Item>
            </Form>

            <ListAddedEntities addedEntities={addedEntities} />
        </Card>
        </Modal>
        <Button 
            type={ buttonText ? "primary" : "text"}
            onClick={
                () => setIsAddModalOpen(true)
                }>{ 
                buttonText ? buttonText: "tie a new entity"
                } 
        </Button>
  </React.Fragment>

}

export default AddNewEntity;
