import React, { createContext, useContext,useEffect,useState } from 'react';
import useFirebaseAuth from './useFirebaseAuth/useFirebaseAuth';
import { User } from 'firebase/auth';
import { EntityInterface } from '../types';
import getNotification from '../hooks/notifications/getNotification';
import useNeo4jAuth from './useNeo4jAuth/useNeo4jAuth';


export interface StateContextType {
  user?: User | null | { displayName: undefined; photoURL: undefined; passcode?: string };
  userEntity: EntityInterface | null;
  notifications: any;
  userState: any;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  db: any;
  storage: any;
  functions: any;
  signInEmail?: any;
  signUpEmail?: any;
  changePassword?: any;
  onboardEntries?: any;
  neo4jDriver?: any;
}

export const StateContext = createContext<StateContextType>(null!);

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  //console.log("test - app context ");

  
  let contextValue = {
  } as StateContextType;

  contextValue = {
    ...contextValue,
    ...useFirebaseAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    ...useNeo4jAuth(), // eslint-disable-line react-hooks/rules-of-hooks
  };

  const { db, userEntity } = contextValue;
  const [notifications, setNotications] = useState<any>([]);
  useEffect(() => {
    if (db && userEntity?.id) {
      console.log("getting notifications : ", userEntity?.id)
      getNotification(db, userEntity?.id, setNotications);  
    }
  }, [db, userEntity?.id]);

  useEffect(() => {
    console.log("notifications in the context file ... ", notifications)
  }, [notifications]);

  return <StateContext.Provider 
        value={{ 
          ...contextValue,
          notifications
        }}>
    {props.children}
    </StateContext.Provider>;
}

export function useAppState() {

  const context = useContext(StateContext);
  //console.log(context)

  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
