import { useCallback, useEffect, useState } from 'react';
import {initializeApp} from 'firebase/app';
import { 
  getAuth, 
  onAuthStateChanged, 
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  browserSessionPersistence,
  browserLocalPersistence,
  setPersistence,
  User,
  Auth
 } from "firebase/auth";

import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from 'firebase/functions';
import 'firebase/storage';
import { EntityInterface } from '../../types';

const firebaseConfig = {
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};


// setPersistence(auth, inMemoryPersistence);

export default function useFirebaseAuth() {

  const [user, setUser] = useState<User | null>(null);
  const [isAuthReady, setIsAuthReady] = useState(false);
  const [db, setDB] = useState<any>(null);
  const [storage, setStorage] = useState<any>(null);
  const [userEntity, setUserEntity] = useState<EntityInterface | null>(null);
  const [userState, setUserState] = useState<any>(null);
  const [functions, setFunctions] = useState<any>(null);

  useEffect(() => {

    initializeApp(firebaseConfig);
    const auth = getAuth();

    onAuthStateChanged(auth, (updatedUser) => {
      setDB(getFirestore());
      console.log("updatedUser", updatedUser);
      if (updatedUser) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
        
          setUser(updatedUser);
          setStorage(getStorage());
          setFunctions(getFunctions());

        } else {
          // User is signed out
          // ...
        }

        setIsAuthReady(true);
      }

    );

  }, []);

  const getUserEntity = useCallback(async () => {
    if (user !== null) {
      const docRef = doc(db, "entities", user.uid);
      const docSnap = await getDoc(docRef);
    
      if (docSnap.exists()) {

        console.log("Document data:", docSnap.data());

        let entityFields: EntityInterface = {

          id: docSnap.id,
          name: '',
          entityType: 'person',
          description: '',
          photoURL: '',  ...docSnap.data()

        };
        setUserEntity(entityFields);

      } else {

        // doc.data() will be undefined in this case
        console.log("No such document!");

      }
    }
  }, [user, db]);

  const getUserState = useCallback(async () => {
    if (user !== null) {
      const docRef = doc(db, "entities", user.uid);
      const docSnap = await getDoc(docRef);
    
      if (docSnap.exists()) {

        console.log("Document data:", docSnap.data());
        let entityFields: EntityInterface = {
          id: docSnap.id,
          name: '',
          entityType: 'person',
          description: '',
          photoURL: '',  ...docSnap.data()
        };
        setUserState(entityFields);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");

      }
    }
  }, [user, db]);

  useEffect(() => {
    getUserEntity();
    getUserState();
  }, [user, getUserEntity, getUserState]);


  const setUserFromResponse = (response: any) => {
    setUser(response.user);
    console.log('signin with email password response', response);
  }

  // Sign up 
  const signInEmail = useCallback((email:string, password:string, remember:boolean) => {
      const auth = getAuth();
      
      return remember ? setPersistence(auth, browserLocalPersistence).then(() => { 
        console.log('inmemory')
        return signInWithEmailAndPassword(auth, email, password)
        .then(setUserFromResponse)
      }) : setPersistence(auth, browserSessionPersistence).then(() => {
        console.log('browserSession')
        return signInWithEmailAndPassword(auth, email, password)
        .then(setUserFromResponse)})

  }, []);

    const signUpEmail = useCallback((email:string, password: string) => {
      const auth = getAuth();
      return createUserWithEmailAndPassword(auth, email, password);
    }, []);

    const changePassword = useCallback((email:string ) => {
      const auth = getAuth();

      return sendPasswordResetEmail(auth, email).then(() => {
        // Password reset email sent!
        // ..
      }).catch((error:any) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log("error", errorCode, errorMessage);
      });
    }, []);


    // MOVE OUT ::
    const signOut = useCallback(() => {
      const auth:Auth = getAuth();
      return auth.signOut().then(() => {
          setUser(null);
        });
    }, []);


  return {
    user,
    userEntity,
    userState,
    signOut,
    isAuthReady,
    db,
    functions,
    storage,
    signInEmail,
    signUpEmail,
    changePassword
  };
}


/*

  useEffect(() => {
    if (user !== null) {
      const entityRef = firebase.firestore().doc(`entities/${user.uid}`);
      const unsubscribe = entityRef.onSnapshot((doc: any) => {
        let entityFields: EntityInterface = { ...doc.data(), id: doc.id };
        setUserEntity(entityFields);
        // console.log('doc', entityFields);
      });

      return () => {
        try {
          unsubscribe();
        } catch (e) {
          console.log('error in useGetEntityDetails:', e);
        }
      };
    }
  }, [user]);


  // WTF ...
  const onboardEntries = (username: any, displayname: any, photourl: any, description: any) => {
    console.log(username, displayname, photourl, description);

    // @ts-ignore
    firebase.auth().currentUser?.updateProfile({ displayName: displayname, photoURL: photourl });
    // @ts-ignore
    let userRef = db.collection('users').doc(user?.uid);

    userRef
      .get()
      .then((docSnapshot: any) => {
        if (docSnapshot.exists) {
          userRef.update({
            username,
            displayname,
            description,
            id: user?.uid,
            photoURL: photourl,
            mentor: false,
          });
        } else {
          userRef.set({
            username,
            displayname,
            description,
            id: user?.uid,
            photoURL: photourl,
            mentor: false,
          });
        }
      })
      .catch((e: any) => console.log('error', e));
  };

  */