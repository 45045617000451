import React from "react";
import { RulesCard } from "../RulesModal";
import { Tabs } from "antd"




const Overview = () => {

  return  <>
    <Tabs
    tabPosition='left'
    items={[
      {"label" : "entities", "key": "entities & ties", "children": tabChildren["entities"]},
      {"label" : "rules", "key": "rules", "children": tabChildren["rules"]},
      {"label" : "invite", "key": "invite", "children": tabChildren["invite"]},
      {"label" : "hide", "key": "intro", "children": tabChildren["hide"]},
      {"label" : "admin", "key": "admin", "children": tabChildren["admin"]},
      {"label" : "sponsor", "key": "support", "children": tabChildren["support"]},
      {"label" : "comms", "key": "comms", "children": tabChildren["comms"]}
    ]
    }>
    </Tabs>

  </>
};
const tabChildren = {
  "entities":    <img 
  width={280}
  src={"/images/entities.png"} 
  alt="Entities"
  />,
"invite": <img
width={280}
src={"/images/invite.png"}
alt="Invite"
/>,
"rules": <RulesCard showSelector entityType='relationship'/>,

"support": <img
width={280}
src={"/images/support.png"}
alt="sponsor"
/>,
"comms": <img
width={280}
src={"/images/comms.png"}
alt="comms"
/>,
"hide": <img
width={280}
src={"/images/hide.png"}
alt="Hide"
/>,
"admin": <img
width={280}
src={"/images/admin.png"}
alt="Admin"
/>,
"notifications": <img
width={280}
src={"/images/notifications.png"}
alt="Notifications"
/>
}




export default Overview;