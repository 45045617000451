import React, {useState} from "react";
import { Button, Typography } from "antd";
import { useAppState } from "../../state";
import setClicked from "../../hooks/notifications/setClicked";
import { useNavigate } from "react-router-dom";

const RenderNotification = (props : { notification : any}) => {
    const { Text } = Typography;
    const { notification } = props;
    const nav = useNavigate();
    const { db, userEntity} = useAppState();
    const [clicked, localSetClicked] = useState( notification.clicked);
    
    return <> 
        <br/>
        <Button type='text'>
        <Text  
            key={notification.id}
            mark={!clicked}
            onClick={() => {
                if (userEntity?.id && notification?.id) {
                    setClicked( db, userEntity.id, notification.id);
                    localSetClicked(true);
                }
                nav(notification.link);
            }}> {notification.message}</Text>
        </Button>        
    </>

}

export default RenderNotification;
