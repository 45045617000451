import React from "react";
import { Tabs } from "antd"


const Communities = () => {

  return  <>
    <Tabs
      tabPosition='left'
      items={[
        {"label" : "ecologies of ideas", "key": "ecologies", "children": tabChildren["ecologies"]},
        {"label" : "emergences", "key": "meeting_challenges", "children": tabChildren["ecologies"]},
        {"label" : "caring communties", "key": "vitality", "children": tabChildren["meeting_challenges"]},
        {"label" : "community sponsored [merge above]", "key": "admin", "children": tabChildren["community_supported"]},
      ]}>
  </Tabs>
  </>
};

const tabChildren = {
  "meeting_challenges": <img 
  width={280}
  src={"/images/meeting_challenges.png"} 
  alt="characterestics"
  />,
  "community_supported": <img 
  width={280}
  src={"/images/community_supported.png"} 
  alt="community supported"
  />,
  "ecologies": <img
  width={280}
  src={"/images/ecologies.png"}
  alt="ecologies"
  />,
}


export default Communities;