import React from 'react';
import { Tabs } from 'antd';
import { RulesCard } from "./RulesModal";
import Intro from './ConceptTabs/Intro';
import Core from './ConceptTabs/Core';
import EntitiesAndTies from './ConceptTabs/Overview';
// import Stochasticity from './ConceptTabs/Stochasticity';
import Communities from './ConceptTabs/Communities';
import Background from './ConceptTabs/background';


const tabChildren = {
  "intro":  <Intro />,
  "core": <Core/>,
"ties": <>
<b>Build Ties</b>
<br/>
Ties are the links between entities. Building these ties requires the user to follow certain rules.
<br/>
Click on the different entity types below to see the relevant rules.

<RulesCard showSelector entityType='relationship'/>

</>,
"overview":<EntitiesAndTies />,
"background": <Background />,
"communities": <Communities />,
"invite": <img
  width={280}
  src={"/images/invite.png"}
  alt="Invite"
/>,
"insights": <img
  width={280}
  src={"/images/insights.png"}
  alt="Insights"
/>,
"support": <img
  width={280}
  src={"/images/support.png"}
  alt="Support"
/>,
"hide": <img
width={280}
src={"/images/hide.png"}
alt="Hide"
/>,
"admin": <img
width={280}
src={"/images/admin.png"}
alt="Admin"
/>,
"notifications": <img
width={280}
src={"/images/notifications.png"}
alt="Notifications"
/>,

}

const ConceptTabs: React.FC = () => {
  /*
  const query = `MATCH (m:Concept {name: $name}) RETURN m`
  const params = { name: 'New Flavor' }

  const { loading, first, error } = useReadCypher(query, params)
  */

  return <><Tabs
    tabPosition='left'
    items={
      [
        {"label" : "intro-", "key": "intro", "children": tabChildren["intro"]},
        {"label" : "what you will find", "key": "support", "children": tabChildren["communities"]},
        {"label" : "core concepts", "key": "core", "children":  tabChildren["core"]},
        {"label" : "in the background", "key": "stochasticity", "children": tabChildren["background"]},
        {"label" : "platform overview", "key": "entities", "children": tabChildren["overview"]},
      ]
  }/> 

  </>

};


export default ConceptTabs;