import { useEffect, useState } from "react";
import neo4j from "neo4j-driver";

export default function useNeo4jAuth() {

    
    const [driver, setDriver] = useState<any>(null);

    useEffect(() => {
        const connection = neo4j.driver('neo4j://35.228.13.102:7687/', neo4j.auth.basic('', '')); 
        setDriver(connection);
        
    }, []);

    return {
        neo4jDriver: driver
    };
}

