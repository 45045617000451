import React from "react";
import { Tabs } from "antd"

const tabChildren = {
"insights": <img
width={280}
src={"/images/insights.png"}
alt="Insights"
/>,
"boundaries":<img
width={280}
src={"/images/boundaries.png"}
alt="boundaries"
/>,
"search": <img
width={280}
src={"/images/search.png"}
alt="Search"
/>,
"graph_view": <img
    width={280}
    src={"/images/graph_view.png"}
    alt="graph view"
  />
}

const Background = () => {
  return  <>
    <Tabs
    tabPosition='left'
    items={[
      {"label": "boundaries", "key": "boundaries", "children": tabChildren["boundaries"]},
      {"label" : "search engine", "key": "search", "children": tabChildren["search"]},
      {"label" : "graph engine", "key": "graph_view", "children": tabChildren["graph_view"]},
      {"label" : "insights engine", "key": "insights", "children": tabChildren["insights"]}
    ]}>
    </Tabs>
  </>
};

export default Background;